
<template>
  <div class="container">
    <div class="title">
      <div class="left_mess_bottom">
        <div class="mess_item flex_box">
          <label class="lab">Name: </label>
          <div class="txt">{{ obj.name }}</div>
        </div>
        <div class="mess_item flex_box">
          <label class="lab">Other Name:</label>
          <div class="txt">{{ obj.othername }}</div>
        </div>
        <div class="mess_item flex_box">
          <label class="lab">Gender:</label>
          <div class="txt">{{ obj.gender }}</div>
        </div>
        <div class="mess_item flex_box">
          <label class="lab">Age:</label>
          <div class="txt">{{ obj.Age }}</div>
        </div>
        <div class="mess_item flex_box">
          <label class="lab">Country:</label>
          <div class="txt">
            <div class="txt flex_align_center"  ><img v-for="(item,index) in imglist" style="cursor: pointer;" @click="gotocountry(item)"  :key="index" :src="item.img" />{{ obj.Country }}</div>
            <!-- <div class="txt flex_align_center" v-show="showTranslate"><img v-for="(item,index) in imglist"  @click="gotocountry(item)"  :key="index" :src="item.img" />{{ obj.countryCn }}</div> -->
          </div>
        </div>
        <div class="mess_item flex_box">
          <label class="lab">Roles:</label>
          <div class="txt">{{ obj.roles }}</div>
        </div>
        <div class="mess_item flex_box">
          <label class="lab">Relations:</label>
          <div class="txt">{{ obj.Relations }}</div>
        </div>
        <div class="mess_item flex_box">
          <label class="lab">Phone:</label>
          <div class="txt">{{ obj.Phone }}</div>
        </div>
        <div class="mess_item flex_box">
          <label class="lab">Email:</label>
          <div class="txt">{{ obj.Email }}</div>
        </div>
      </div>
      <div class="xian"></div>
      <div class="left_mess_bottom2">
        <div class="mess_item flex_box">
          <label class="lab">Experience: </label>
          <div class="txt" v-show="showTranslate">{{  obj.experience }}</div>
          <div class="txt" v-show="!showTranslate">{{  obj.experienceEn }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getpersonnel } from '../../api/userApi'
import { mapGetters, mapActions} from 'vuex'
import { mixins1 } from '@/mixins/index'
export default {
  mixins: [mixins1],
  props:{
    showTranslate: {
      type: Boolean,
    },
  },
  data() {
    return {
      imglist:[],
      obj: {
        pnameEn:'',
        name: '',
        othername: '',
        Relations: '',
        Phone: '',
        Age: '',
        Experience: '',
        roles: '',
        gender: '',
        Country: '',
        img: '',
        Website: '',
        Email: '',
        Form: '',
        Year: '',
        Status: '',
        Listed: '',
        SIC: '',
        Activity: '',
        Products: '',
        Business: '',
        Revenue: '',
        Assets: '',
        ProfitLoss: '',
        Employee: 'n.a.',
        Litigation: '0',
        Judgments: '0',
        BlackList: '0',
        Media: '0',
      },
    }
  },
  computed: {
    // ...mapGetters({
    //   userlist: 'User/userlist1',
    // }),

    openMenu: {
      get() {
        return this.menuTitleList.map((i) => {
          return i.title
        })
      },
      set() {},
    },
  },
  watch: {
  
  },
  mounted() {
    this.huoqugetpersonner()
  },

  methods: {
    // ...mapActions({
    //   getuserlist:'User/getuserlist'
    // }),
     // 跳转国家
     gotocountry(row){
            let routeData= this.$router.resolve({
                path: '/country?'  + '&companyCountry=' + row.countryCode,
            });
      
            window.open(routeData.href, '_blank');
        },
    async huoqugetpersonner() {
      this.$parent.getMenuList()
      const pid = this.$route.query.pid
      // const name=this.$route.query.name
      const params = {
        // page: 1,
        // pageSize: 10,
        // roleList: '',
        // name: name,
        pid: pid,
        // countryCode:company.countryCode
        // industryCodeList: '',
        // companyStatusCodeList: '',
      }
      // this.getuserlist(params)
      const res = await getpersonnel(params)
      if(res.code){
        console.log(`res`, res.data.rows);
        res.data.rows[0].countryCode.split(';')
        let countrylist= res.data.rows[0].countryCode.split(';')
        // console.log(countrylist);
        let countryImg=[]
        countrylist.map(item=>{
          console.log(item);
          countryImg.push({img:this.getCountryImgUrl(item),countryCode:item})
        })
        // console.log(countryImg);
      this.obj=res.data.rows[0]
      this.obj.name = res.data.rows[0].pnameEn
      let imglist=[]
      countryImg.map(item=>{
      console.log(item);
     imglist.push({img:item.img==undefined?'':require('../../../static' + item.img),countryCode:item.countryCode})
      })
      this.imglist=imglist
      // this.obj.img=countryImg==undefined?'':require('../../../static' + '/imagev2/South America/AR.png') ,
      this.obj.othername = res.data.rows[0].pname
      this.obj.gender = res.data.rows[0].gender
      this.obj.Age = res.data.rows[0].age
      this.obj.roles = res.data.rows[0].roleNum
      this.obj.Relations = res.data.rows[0].relationNum
      this.obj.Phone = res.data.rows[0].phone
      this.obj.Email = res.data.rows[0].email
      this.obj.Experience = res.data.rows[0].experienceEn
      this.obj.Country = res.data.rows[0].countryEn
      this.obj.pnameEn = res.data.rows[0].pnameEn
      }else{
        this.obj={}
      }
      // console.log(res.data.rows[0]);
 
   
    },

  },
}
</script>
<style scoped lang="less">
.cont_m {
  margin-top: 30px;
  margin-bottom: 80px;
}

.Company Snapshot {
}

.Country Data {
}
.title {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}
.left_mess_bottom {
  margin-top: 20px;
  width: 432px;
}
.left_mess_bottom2 {
  margin-top: 20px;
  width: 700px;
}

.mess_item {
  font-size: 14px;
  margin-bottom: 20px;
}

.mess_item .lab {
  width: 84px;
  color: #8497ab;
}

.mess_item .txt {
  // width: 420px;
  color: #022955;
  margin-left: 20px;
  text-align: justify;
  display: flex;
  align-items: center;
}
.flex_align_center {
  margin-left: 0px !important;
}
.mess_item .txt img {
  width: 52px;
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
  margin-right: 5px;
}

.mess_item .txt a {
  color: #1290c9;
  text-decoration: underline;
}
.xian {
  // height: 100%;
  margin-top: 20px;
  margin-right: 50px;
  width: 1px;
  width: 1px;

  border: 1px solid #e7eaed;
}
</style>